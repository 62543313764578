//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    headline: {
      type: String,
      default: ''
    },
    image1: {
      type: Object,
      default: () => {}
    },
    image2: {
      type: Object,
      default: () => {}
    },
    link1: {
      type: String,
      default: null
    },
    link2: {
      type: String,
      default: null
    }
  },
  setup () {
    return {}
  }
})
